import { Injectable } from '@angular/core';

export const FLASHY_AUTH_TOKEN = 'flashy_token';
export const FLASHY_TOKENS = 'flashy_tokens';
export const FLASHY_AUTH_HASH = 'flashy_auth_hash';

@Injectable({
  providedIn: 'root',
})
export class FlashyTokenService {
  get token(): string | null {
    return localStorage.getItem(FLASHY_AUTH_TOKEN);
  }

  set token(token: string | null) {
    localStorage.setItem(FLASHY_AUTH_TOKEN, String(token));
  }

  get tokens(): Record<string, string> {
    const tokens = localStorage.getItem(FLASHY_TOKENS);
    return tokens ? JSON.parse(tokens) : {};
  }

  set tokens(tokens: Record<string, string>) {
    localStorage.setItem(FLASHY_TOKENS, JSON.stringify(tokens));
  }

  clear(): void {
    localStorage.removeItem(FLASHY_AUTH_TOKEN);
  }
}
